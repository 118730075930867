<template>
    <div class="tinymce-editor">
        <editor id="tinymce" v-model="myValue" :init="init" @onClick="onClick" style="width:800px;height:400px"></editor>
    </div>
</template>

<script>
// 文档 http://tinymce.ax-z.cn/
// 引入组件
import axios from 'axios';
import tinymce from 'tinymce/tinymce' // tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'

// 引入富文本编辑器主题的js和css
// import 'tinymce/skins/content/default/content.css'
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/icons/default/icons' // 解决了icons.js 报错Unexpected token '<'

// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image' // 插入上传图片插件
import 'tinymce/plugins/media' // 插入视频插件
import 'tinymce/plugins/table' // 插入表格插件
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/wordcount' // 字数统计插件
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
export default {
    components: {
        Editor
    },
    name: 'Tinymce',
    props: {
        // 默认的富文本内容
        value: {
            type: String,
            default: ''
        },
        // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
        // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
        baseUrl: {
            type: String,
            default: window.location.origin ? window.location.origin : ''
        },
        // 禁用
        disabled: {
            type: Boolean,
            default: false
        },
        plugins: {
            type: [String, Array],
            default: 'link lists image code table wordcount media preview fullscreen help'
        },
        toolbar: {
            type: [String, Array],
            default: 'undo redo | formatselect | bold italic underline | forecolor backcolor | link unlink code lists table image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent blockquote | removeformat | fullscreen preview'
        },
        height: {
            type: Number,
            default: 500
        }
    },
    data() {
        return {
            //初始化配置
            init: {
                language_url: `${this.baseUrl}/tinymce/zh_CN.js`,
                language: 'zh_CN',
                skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
                // skin_url: 'tinymce/skins/ui/oxide-dark', // 暗色系
                convert_urls: false,
                height: this.height,
                // content_css（为编辑区指定css文件）,加上就不显示字数统计了
                // content_css: `${this.baseUrl}tinymce/skins/content/default/content.css`,
                // （指定需加载的插件）
                plugins: this.plugins,
                toolbar: this.toolbar, // （自定义工具栏）

                statusbar: true, // 底部的状态栏
                menubar: 'file edit insert view format table tools', // （1级菜单）最上方的菜单 help 帮助
                branding: false, // （隐藏右下角技术支持）水印“Powered by TinyMCE”
                paste_data_images: true, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像
                // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
                // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
                images_upload_handler: (blobInfo, success, failure) => {
                    // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
                    // success(img)
                    // console.log(failure)
                    const formData = new FormData();
                    formData.append('file', blobInfo.blob());
                    axios.request({
                        method: 'post',
                        url: this.$api.upload + '?token=850cc37cdce1fac6bf78285384da6655',
                        data: formData,
                        dataType: 'json',
                    }).then(function (res) {
                        console.log(res.data.data)
                        let file = res.data.data.file_url;
                        success(file);
                    }).catch(function (err) {
                        console.log(err)
                    })

                }
            },
            myValue: this.value
        };
    },
    mounted() {
        tinymce.init({});
    },
    methods: {
        //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
        //需要什么事件可以自己增加
        onClick(e) {
            this.$emit('onClick', e, tinymce);
        },
        //可以添加一些自己的自定义事件，如清空内容
        clear() {
            this.myValue = '';
        }
    },
    watch: {
        value(newValue) {
            this.myValue = newValue;
        },
        myValue(newValue) {
            this.$emit('input', newValue);
        }
    }
};
</script>